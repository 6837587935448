<template>
  <v-card tile :elevation="0">
    <v-card-title>Import Pdf Labels</v-card-title>
    <v-card class="pa-4" tile :elevation="0" style="border-bottom: 1px solid #ddd">
      <v-row>
        <v-col cols="6">
          <v-file-input style="max-width: 300px"
                        placeholder="Import Excel"
                        outlined dense
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        @change="loadExcel"
                        v-model="excel.file">
          </v-file-input>
        </v-col>
        <v-col cols="6">
          <v-layout wrap>
            <v-file-input style="max-width: 300px"
                          placeholder="Import PDF"
                          outlined dense
                          accept="application/pdf"
                          @change="loadPdf"
                          v-model="pdf.file">
            </v-file-input>
            <div class="float-end">
              <v-card-text v-if="pdf.file">Loading {{ pdf.pageLoading }} on total {{ pdf.pages }} pages</v-card-text>
            </div>
          </v-layout>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-for="(trackingNumber, orderIndex) in listPDFTrackingNumbers" :key="orderIndex" class="px-4 py-1" tile :elevation="0"
            style="border-bottom: 2px solid #ddd">
      <v-row>
        <v-col cols="6">
          <table v-if="listExcelOrderData[orderIndex]">
            <tr v-for="(fieldName, fieldIndex) in Object.keys(listExcelOrderData[orderIndex])" :key="fieldIndex">
              <td>{{ fieldName }}</td>
              <td v-if="fieldName !== 'date'">{{ listExcelOrderData[orderIndex][fieldName] }}</td>
              <td v-else>{{ new Date(listExcelOrderData[orderIndex][fieldName]).toISOString().slice(0, 10) }}</td>
            </tr>
          </table>
        </v-col>
        <v-col cols="6">
          <div class="pa-4" style="border-left: 2px solid #ddd; max-width: 640px;">
            <img style="width: 100%" v-for="(base64ImgStr, idx) in labelTrackingData[trackingNumber]" :src="base64ImgStr" :key="idx"/>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-btn v-if="listPDFTrackingNumbers.length > 0 && listExcelOrderData.length > 0" color="success" class="mr-2 mt-2 ml-2 text-white btn-unpaid-order"
           @click="onClickImport">
      <v-icon>mdi-database-import</v-icon>
      Import
    </v-btn>
    <canvas ref="pdfBoard" style="display: none"></canvas>
    <p v-if="responseCallAPI.length > 0" class="mr-2 ml-2 mt-2" style="font-size: 16px">Kết quả</p>
    <v-simple-table v-if="responseCallAPI.length > 0" style="border: 1px solid #ddd;" class="mr-2 ml-2 mt-2">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left" style="width: 250px">
            Reference
          </th>
          <th class="text-left" style="width: 100px">
            Status
          </th>
          <th class="text-left">
            Error Message
          </th>
          <th class="text-left w-50" style="width: 100px">
            Action
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="item in responseCallAPI"
          :key="item.name"
        >
          <td>{{ item.orderCode }}</td>
          <td :style="{ color: item.status === 'Success' ? '#4caf50' : 'red' }">{{ item.status }}</td>
          <td>{{ item.message.substring(0, 1000) }}</td>
          <td>
<!--            <v-tooltip bottom v-if="item.status !== 'Success'">-->
<!--              <template v-slot:activator="{ on, attrs }">-->
<!--                <v-icon small @click="reImportOrder(item.orderCode)" v-bind="attrs" v-on="on" class="mr-2" color="primary">-->
<!--                  mdi-import-->
<!--                </v-icon>-->
<!--              </template>-->
<!--              <span>Import lại order này</span>-->
<!--            </v-tooltip>-->
            <v-btn v-if="item.status !== 'Success'" color="primary" class="mr-2 mt-2 mb-2 text-white btn-unpaid-order"
                   @click="reImportOrder(item.orderCode)">
              <v-icon>mdi-import</v-icon>
              Import Lại
            </v-btn>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>
<script>
import Quagga from "@ericblade/quagga2";
import {mapActions} from "vuex";
import readXlsxFile from "read-excel-file";

export default {
  name: 'ImportLabels',
  data: () => ({
    excel: {
      headers: [],
      file: null,
      isLoading: false
    },
    pdf: {
      file: null,
      isLoading: false,
      pages: 0,
      pageLoading: 0,
      document: null,
      scale: 4
    },
    listPDFTrackingNumbers: [],
    labelTrackingData: {},
    listExcelOrderData: [],
    responseCallAPI: [],
    orders: [],
  }),
  methods: {
    ...mapActions({
      toggleAppLoading: 'system/toggleAppLoading',
      importLabelFromPDF: 'order/importLabelFromPDF',
    }),
    async loadPdf() {
      this.pdf.isLoading = true;
      if (!this.pdf.file) return;
      let pdfjsLib = window['pdfjs-dist/build/pdf'];
      pdfjsLib.GlobalWorkerOptions.workerSrc = '/webapp/pdf.worker.js';
      let pdfReader = new FileReader();
      pdfReader.onload = (event) => {
        pdfjsLib.getDocument({data: event.target.result}).promise.then(async (pdfDocument) => {
          this.pdf.document = pdfDocument;
          this.pdf.pages = pdfDocument.numPages;
          while (this.pdf.pageLoading < pdfDocument.numPages) {
            this.pdf.pageLoading++;
            await this.renderPage(this.pdf.pageLoading);
          }
          await new Promise(resolve => setTimeout(resolve, 200));
          this.$forceUpdate();
          this.pdf.isLoading = false;
        });
      };
      pdfReader.readAsArrayBuffer(this.pdf.file);
    },
    async renderPage(pageIndex, totalPages) {
      await new Promise(resolve => {
        let canvas = this.$refs.pdfBoard;
        let ctx = canvas.getContext('2d');
        this.pdf.document.getPage(pageIndex).then(async page => {
          let viewport = page.getViewport({scale: this.pdf.scale});
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          let renderContext = {
            canvasContext: ctx,
            viewport: viewport
          };
          let renderTask = page.render(renderContext);

          // Wait for rendering to finish
          renderTask.promise.then(async () => {
            const img = this.$refs.pdfBoard.toDataURL('image/png');
            let trackingNumber = await this.readBarcode(img);
            if (trackingNumber !== '') {
              this.listPDFTrackingNumbers.push(trackingNumber);
              this.labelTrackingData[trackingNumber] = [img]
            } else {
              let lastTrackingNumberIndex = this.listPDFTrackingNumbers.length - 1;
              trackingNumber = this.listPDFTrackingNumbers[lastTrackingNumberIndex];
              if (trackingNumber) this.labelTrackingData[trackingNumber].push(img)
            }
            if (pageIndex < totalPages) await this.renderPage(pageIndex + 1, totalPages);
            else resolve();
          });
        });
      })
    },
    async readBarcode(image) {
      let code = '';
      await Quagga.decodeSingle({
        decoder: {
          readers: ["code_128_reader"],
        },
        inputStream: {
          size: 2400,
        },
        locate: true, // try to locate the barcode in the image
        src: image // or 'data:image/jpg;base64,' + data
      }, result => {
        if (result && result.codeResult) {
          code = result.codeResult.code;
        }
      });
      return code;
    },
    loadExcel() {
      this.excel.isLoading = true;
      readXlsxFile(this.excel.file).then((data) => {
        if (data && data.length > 0) this.analyzeExcelData(data)
      })
    },
    analyzeExcelData(data) {
      if (data.length < 2) return this.excel.isLoading = false;
      this.excel.headers = data[0];
      for (let i = 1; i < data.length; i++) {
        const obOrder = data[i];
        let orderData = {}
        for (const dataField in obOrder) {
          let key = this.excel.headers[dataField] ? this.excel.headers[dataField].replaceAll(' ', '_').toLowerCase() : '';
          orderData[key] = obOrder[dataField];
        }
        this.listExcelOrderData.push(orderData);
      }
      this.excel.isLoading = false;
    },
    async onClickImport() {
      if (this.listPDFTrackingNumbers.length !== this.listExcelOrderData.length) return this.noticeError('Excel data not match with PDF order list, please re-check');
      this.orders = [];
      for (let i = 0; i < this.listExcelOrderData.length; i++) {
        let orderData = this.listExcelOrderData[i];
        let trackingNumber = this.listPDFTrackingNumbers[i];
        let labelData = this.labelTrackingData[trackingNumber].map(label => label.replace('data:image/png;base64,', ''));
        this.orders.push({
          orderCode: orderData.reference,
          trackingNumber: trackingNumber,
          labelData: labelData,
        });
      }
      // this.importLabelFromPDF({orders: orders});
      let i = 0;
      this.responseCallAPI = [];
      this.toggleAppLoading(true);
      try{
        while (i < this.orders.length) {
          await this.importOrder(this.orders[i])
          await new Promise(resolve => setTimeout(resolve, 2000));
          i++;
        }
        this.toggleAppLoading(false);
      } catch (e) {
        this.toggleAppLoading(false);
      }
    },

    async reImportOrder(code) {
      const findIndex = this.orders.findIndex(r => r.orderCode == code )
      if (findIndex > -1) {
        await this.importOrder(this.orders[findIndex])
      }
    },
    async importOrder(item) {
      try {
        let res = await this.importLabelFromPDF({orders: [item], silent: true})
        if (res.status === 200 && res.data && res.data && !res.data.error) {
          const findIndex = this.responseCallAPI.findIndex(r => r.orderCode == item['orderCode'] )
          if (findIndex < 0) {
            this.responseCallAPI.push({
              orderCode: item['orderCode'],
              status: 'Success',
              message: '',
            })
          } else this.responseCallAPI[findIndex]['status'] = 'Success';
        } else {
          this.responseCallAPI.push({
            orderCode: item['orderCode'],
            status: 'Fail',
            message: res.data.message,
          })
        }
      } catch (e) {
        const findIndex = this.responseCallAPI.findIndex(r => r.orderCode == item['orderCode'] )
        if (findIndex < 0) {
          this.responseCallAPI.push({
            orderCode: item['orderCode'],
            status: 'Fail',
            message: e.response.data.message,
          })
        }
      }
    }
  },
  watch: {
    'pdf.isLoading': {
      handler() {
        this.toggleAppLoading(this.pdf.isLoading);
      },
      deep: true
    }
  }
}
</script>
